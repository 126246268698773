'use client';

import { FC, memo } from 'react';

import { MessageType } from '@prisma/client';
import cn from 'classnames';
import Linkify from 'linkify-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import MarkdownImage from './markdown-image.component';

export type MessageMarkdownProps = {
  className?: string;
  children: string;
  type: MessageType;
};

const MessageMarkdown: FC<MessageMarkdownProps> = ({
  className,
  children,
  type,
}) => {
  return (
    <>
      <ReactMarkdown
        className={cn(
          'prose break-words leading-[inherit] text-inherit',
          className,
        )}
        components={{
          p: (props) => (
            <Linkify
              options={{
                render: ({ attributes, content }) => {
                  return (
                    <a
                      {...attributes}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      {content}
                    </a>
                  );
                },
              }}
            >
              <p>{props.children}</p>
            </Linkify>
          ),
          a: (props) => {
            if (props.href.startsWith('#'))
              return <span>{props.children}</span>;

            return (
              <a
                href={props.href}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                {props.children}
              </a>
            );
          },
          img: (props) => <MarkdownImage src={props.src || ''} />,
        }}
        remarkPlugins={[remarkGfm]}
        remarkRehypeOptions={{
          footnoteBackContent: (
            referenceIndex: number,
            rereferenceIndex: number,
          ) => {
            return {
              type: 'element',
              tagName: 'sup',
              properties: {},
              children: [{ type: 'text', value: String(rereferenceIndex) }],
            };
          },
        }}
      >
        {type === MessageType.TEXT ? children : `![Image message](${children})`}
      </ReactMarkdown>
    </>
  );
};

export default memo(MessageMarkdown);
