'use client';

import { FC } from 'react';
import * as yup from 'yup';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Input from '@/components/input.component';
import Button from '@/components/button.component';
import useTranslationStore from '@/store/translation';
import PlaneIcon from '../icons/plane-icon.component';
import { MessageBoxStyle } from './message-box.component';

export const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('This field should be a valid email address.')
    .required('This field is required!'),
});

type FormData = {
  email: string;
};

type CollectEmailProps = {
  className?: string;
  handleSubmit?: (value: string) => void;
  style: MessageBoxStyle;
};

const CollectEmail: FC<CollectEmailProps> = ({
  className,
  handleSubmit,
  style,
}) => {
  const { getValue } = useTranslationStore();

  const { register, handleSubmit: handleFormSubmit } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const onSubmit = handleFormSubmit((data: FormData) => {
    if (handleSubmit) handleSubmit(data.email);
  });

  return (
    <div
      className={cn(
        'inline-flex flex-col mt-3 sm:max-w-[268px] w-full',
        className,
      )}
    >
      <div
        data-testid="collect-email-wrapper"
        className="py-3 px-[15px] rounded-lg w-full"
        style={{
          background:
            style?.backgroundColor ||
            'linear-gradient(339.94deg, #F4FBFF 55.36%, rgba(244, 251, 255, 0) 98.66%)',
          color: style.textColor,
          border: `1px solid ${style.borderColor}`,
        }}
      >
        <h6 data-testid="collect-email-title" className="text-xs mb-2">
          {getValue('emailLabel')}
        </h6>
        <form
          data-testid="collect-email-form"
          id="collect-email-input-wrapper"
          className="bg-white flex items-center pl-[10px] pr-[4px] py-[4px] border rounded-md border-[#EAEAEA] min-w-[200px]"
          onSubmit={onSubmit}
        >
          <Input
            className="w-full"
            inputClassName="!text-xs"
            variant="messenger"
            placeholder={getValue('emailPlaceholder')}
            aria-label="email input"
            data-testid="collect-email-input"
            required //for native html errors
            type="email" //for native html errors
            {...register('email')}
          />
          <Button
            variant="unstyled"
            data-testid="collect-email-submit"
            type="submit"
            className="rounded aspect-square !p-[5px]"
            style={{
              background:
                style?.backgroundColor ||
                'linear-gradient(339.94deg, #F4FBFF 55.36%, rgba(244, 251, 255, 0) 98.66%)',
              color: style.textColor,
              border: `1px solid ${style.borderColor}`,
            }}
          >
            <PlaneIcon width="16" height="16" />
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CollectEmail;
