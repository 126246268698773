'use client';

import { FC, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';

import { inIframe } from '@/utils/element';

/* eslint-disable @next/next/no-img-element */

type MarkdownImageProps = {
  src: string;
};

const MarkdownImage: FC<MarkdownImageProps> = ({ src }) => {
  const [showBigger, setShowBigger] = useState(false);

  function openImage() {
    const isInIframe = inIframe();

    if (isInIframe) {
      window.parent.postMessage({ key: 'open-image-modal', value: src }, '*');
    } else {
      setShowBigger(true);
    }
  }

  function toggleImage() {
    setShowBigger(false);
  }

  return (
    <>
      <img
        src={src}
        onClick={openImage}
        className="max-h-64 max-w-full cursor-pointer object-contain"
        alt="Markdown Image"
      />
      {showBigger && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={toggleImage}
        >
          <button
            className="absolute right-3 top-3 rounded-full p-1 font-bold text-white"
            onClick={toggleImage}
          >
            <XMarkIcon height={20} width={20} className="fill-white" />
          </button>
          <div className="flex items-center justify-center">
            <img
              src={src}
              alt="Image"
              className="h-full max-h-[60%] w-full max-w-[60%] rounded-lg border border-gray-200 bg-white object-contain p-4"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MarkdownImage;
